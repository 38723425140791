@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
body,
h1, h2, h3, h4, h5, 
p, dl, dd, blockquote {
	margin: unset;
}
ul, ol {
	margin: unset;
	padding: unset;
}
:any-link {
	color: unset;
	text-decoration: unset;
}
html {
	font: 1em/1.75 "Lato", "Arial", sans-serif;
	color: #194167;
	background: white;
}
.sv-site {
	.sv--button {
		display: inline-block;
		padding: 0.5em 1em;
		border: 0.5em solid #E4EEF6;
		background: white;
		outline: thin solid #194167;
		font-weight: bold;
		&:focus,
		&:hover {
			border-color: #194167;
		}
		&:any-link {
			&:focus,
			&:hover {
				text-decoration: none !important;
			}
		}
		&.sv--menu {
			color: white;
			border-color: #3981A5;
			border-radius: unset;
			background: #3981A5;
			outline: none;
		}
	}
	.sv--content {
		margin: {
			right: auto;
			left: auto;
		}
		max-width: 960px;
	}
	.sv-site-header {
		padding: 1.25em 2em;
		font-family: "Source Sans Pro", "Arial", sans-serif;
		:any-link {
			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
		.sv--button {
			position: absolute;
			top: 1rem;
			right: 1rem;
			z-index: 400;
			&[data-toggle] {
				@media (min-width: 1024px) {
					display: none;
				}
			}
		}
		.sv--content {
			@media (min-width: 1024px) {
				display: grid;
				grid-template:
					"heading secondary " 1.5em
					"heading primary   " 1fr
					"heading _         " 1.5em
					/12em    1fr;
			}
		}
		hgroup {
			grid-area: heading;
			img {
				display: block;
				max-height: 3em;
				@media (min-width: 1024px) {
					max-height: 5em;
				}
			}
		}
		.sv-site-header-nav {
			box-sizing: border-box;
			position: fixed;
			padding: 2rem;
			height: 100vh;
			width: 18rem;
			left: 100vw;
			top: 0;
			z-index: 200;
			background: white;
			transition-property: left;
			transition-duration: 0.25s;
			@media (min-width: 1024px) {
				display: contents;
				position: static;
			}
			.sv-site-header-nav-primary {
				font: {
					size: 1.25em;
					weight: 600;
				}
				text-transform: uppercase;
				color: #4395BD;
				list-style: none;
				@media (min-width: 1024px) {
					grid-area: primary;
					display: grid;
					position: relative;
					top: 0.5em;
					grid-auto-flow: column;
					justify-content: end;
					align-items: center;
					column-gap: 1em;
				}
			}
			.sv-site-header-nav-secondary {
				line-height: 1;
				text-transform: uppercase;
				list-style: none;
				.sv--icon {
					display: inline-block;
					img {
						display: block;
						height: 1em;
						width: 1em;
					}
				}
				@media (min-width: 1024px) {
					grid-area: secondary;
					display: grid;
					grid-auto-flow: column;
					justify-content: end;
					column-gap: 1em;
				}
			}
			&.sv--active {
				left: calc(100vw - 18rem);
				box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
			}
		}
	}
	.sv-site-footer {
		padding: 3rem 2rem;
		text-align: center;
		font-family: "Source Sans Pro", "Arial", sans-serif;
		background-color: #E4EEF6;
		:any-link {
			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
		.sv--content {
			gap: 1.5rem 1rem;
			@media (min-width: 1024px) {
				display: grid;
			}
		}
		.sv-site-footer-nav {
			display: grid;
			gap: 1.5rem 2rem;
			justify-content: center;
			align-items: center;
			list-style: none;
			@media (min-width: 1024px) {
				grid-auto-flow: column;
			}
			.sv--learn_more {
				color: #64AED2;
				font-style: italic;
				font-weight: 600;
			}
			li {
				text-align: initial;
				color: black;
				max-width: 20em;
				line-height: 1.5;
			}
			strong {
				display: block;
				font: {
					size: 1.5em;
					weight: 600;
				}
				color: #194167;
			}
		}
		.sv-site-footer-contact,
		.sv-site-footer-colophon {
			a,
			span {
				margin-left: 1em;
			}
			br {
				@media(min-width: 768px) {
					display: none;
				}
			}
		}
	}
}
.sv-page {
	.sv-page-main {
		margin: {
			top: 3rem;
			bottom: 3rem;
		}
		dt {
			margin-top: 1.5em;
			font: {
				weight: bold;
				size: 1.15em;
			}
		}
	}
}
.sv-hero {
	position: relative;
	.sv--content {
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		justify-content: center;
		align-items: center;
	}
	.sv-hero-background {
		img {
			display: block;
			width: 100%;
		}
	}
	.sv-hero-text {
		&.sv--home {
			font: {
				family: "Source Sans Pro", "Arial", sans-serif;
				size: 4vw;
				weight: 300;
			}
			letter-spacing: -0.05em;
			max-width: 9.25em;
			text-align: center;
			line-height: 1.15;
			position: relative;
			top: -3vw;
			right: -8vw;
		}
		&.sv--mission {
			font-size: 2vw;
			max-width: 22em;
			text-align: center;
			line-height: 1.5;
			font-weight: 300;
			position: relative;
			top: -1vw;
			left: -12vw;
			h3 {
				font-size: 1.75em;
				font-weight: normal;
			}
		}
	}
}
.sv-section {
	margin: {
		top: 2rem;
		bottom: 2rem;
	}
	&.sv--callout {
		padding: 3em 2em;
		text-align: center;
		font-size: 1.25em;
		color: white;
		background-color: #6E8AA9;
		blockquote, p {
			margin: {
				top: 0.75em;
				bottom: 0.75em;
			}
		}
		.sv--cite {
			font-weight: 600;
		}
		.sv--content {
			max-width: 38em;
		}
	}
	&.sv--quick_links {
		font-size: 1.15em;
		text-align: center;
		:any-link {
			color: #4395BD;
			text-decoration: underline;
			&:focus,
			&:hover {
				text-decoration: double underline;
			}
		}
		p {
			display: inline-block;
			@media (min-width: 1024px) {
				margin-right: 1em;
			}
		}
		ul {
			list-style: none;
			display: grid;
			column-gap: 1em;
			justify-content: center;
			@media (min-width: 1024px) {
				display: inline-grid;
				grid-auto-flow: column;
			}
		}
	}
	&.sv--split {
		.sv--content {
			grid-template-columns: 25% 1fr;
			column-gap: 3em;
			@media (min-width: 768px) {
				display: grid;
			}
		}
		.sv--split-primary {
			padding: 1rem;
			@media (min-width: 1024px) {
				padding: unset;
			}
		}
		.sv--split-secondary {
			padding: 1rem;
			@media (min-width: 1024px) {
				padding: unset;
			}
			img {
				display: block;
				max-width: 100%;
			}
			&.sv--shunt-h3 {
				@media (min-width: 1024px) {
					margin-top: 5.5em;
				}
			}
			&.sv--quote {
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-family: "Source Sans Pro", "Arial", sans-serif;
				color: #4395BD;
				.sv--cite {
					font-weight: 600;
				}
			}
		}
		.sv--youtube {
			height: 0;
			padding-top: calc((9 / 16) * 100%);
			position: relative;
			margin: {
				top: 1.5em;
				bottom: 1.5em;
			}
			overflow: hidden;
			iframe {
				width: 100%;
				height: 100%;
				border: unset;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		.sv--lead {
			font: {
				size: 1.35em;
				style: italic;
			}
			color: #4395BD;
		}
		.sv--fine {
			font: {
				size: 0.85em;
				style: italic;
			}
		}
		:any-link {
			font-weight: 600;
			color: #4395BD;
			text-decoration: underline;
			&:focus,
			&:hover {
				text-decoration: double underline;
			}
		}
		h3 {
			margin-bottom: 0.75em;
			font-size: 2em;
			font-weight: unset;
		}
		h4 {
			font-size: 1.25em;
		}
		h4,
		p {
			margin: {
				top: 0.75em;
				bottom: 0.75em;
			}
		}
		&.sv--reverse {
			.sv--content {
				grid-template-columns: 1fr 25%;
			}
		}
	}
	&.sv--text {
		padding: 1rem;
		@media (min-width: 1024px) {
			padding: unset;
		}
		h2 {
			max-width: 18em;
			margin: {
				right: auto;
				bottom: 0.75em;
				left: auto;
			}
			text-align: center;
			line-height: 1.1;
			font: {
				weight: 300;
				size: 3.3em;
			}
			color: #3981A5;
		}
		h3 {
			text-transform: uppercase;
			letter-spacing: 0.1em;
			text-align: center;
			font: {
				family: "Source Sans Pro", "Arial", sans-serif;
				size: 1.5em;
				weight: 600;
			}
		}
		:any-link {
			font-weight: 600;
			color: #4395BD;
			text-decoration: underline;
			&:focus,
			&:hover {
				text-decoration: double underline;
			}
		}
	}
	&.sv--linkstrip {
		.sv--content {
			display: flex;
			align-items: center;
			position: relative;
		}
		.sv--linkstrip-pane {
			display: grid;
			padding: {
				top: 1rem;
				bottom: 1rem;
			}
			grid-auto-flow: column;
			column-gap: 1rem;
			align-items: center;
			overflow: hidden;
		}
		.sv--linkstrip-prev,
		.sv--linkstrip-next {
			display: flex;
			position: absolute;
			width: 2rem;
			height: 2rem;
			border: unset;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			font: unset;
			font-size: 0.8rem;
			line-height: 0;
			background: white;
			color: #999;
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
			cursor: pointer;
			&[disabled] {
				box-shadow: 0 0 8px rgba(0, 0, 0, 0.125);
				cursor: not-allowed;
				color: #CCC;
			}
		}
		.sv--linkstrip-prev {
			left: 1rem;
			@media (min-width: 1024px) {
				left: -1rem;
			}
		}
		.sv--linkstrip-next {
			right: 1rem;
			@media (min-width: 1024px) {
				right: -1rem;
			}
		}
		.sv--linkstrip-link {
			img {
				display: block;
				height: 9em;
				box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
			}
		}
	}
	&.sv--flexnav {
		font-weight: 300;
		font-size: 0.9em;
		.sv--content {
			display: grid;
			gap: 1.5rem 1rem;
			padding: 1rem;
			justify-content: center;
			align-items: center;
			@media (min-width: 1024px) {
				padding: unset;
				grid-auto-flow: column;
				grid-template-columns: 3fr 3fr 2fr;
			}
		}
		.sv--button {
			white-space: nowrap;
			text-align: center;
			@media (min-width: 1024px) {
				margin-right: 2rem;
			}
		}
		.sv--rule-right {
			@media (min-width: 1024px) {
				border-right: thin solid currentColor;
			}
		}
		.sv--learn_more {
			color: #4395BD;
			font-weight: normal;
			font-style: italic;
		}
		strong {
			display: block;
			font-size: 1.25em;
			color: #00558A;
		}
		:any-link {
			&:focus,
			&:hover{
				text-decoration: underline;
			}
		}
	}
}
